body {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
}

input,button,textarea{
  font-family: 'Noto Sans', sans-serif;
}

.carousel{
  direction: initial;
}